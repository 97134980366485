import React from "react"
import { Layout, Card, ServicesList, SEO } from "../components"
import { ReactComponent as Logo } from "../../static/logos/footer-logo.svg"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick"

const firstCard = {
  title: "Interior Home Design",
  paragraphs: [
    `Interpretamos el gusto, las pasiones y el estilo de cada cliente. Seleccionamos desde el mobiliario, la decoración,  
    textiles, arte y antigüedades que plasmen la personalidad de nuestros clientes en cada uno de los espacios arquitectónicos 
    del proyecto a realizar.`,
    `Tenemos la visión de ver las cosas bellas y transformarlas en arte creando ambientes elegantes, sofisticados, acogedores y únicos.`,
  ],
}

const secondCard = {
  title: "Nuestro Objetivo",
  paragraphs: [
    `Respondemos a cada proyecto con un enfoque individual teniendo en cuenta la integridad del diseño en cada espacio. 
    Nuestro objetivo es crear espacios lujosos que se adaptan al flujo de la vida cotidiana.`,
    `Nos concentramos en brindarle a nuestros clientes espacios personales para ellos y sus familias, teniendo encuenta 
    la elegancia atemporal que define un proyecto.`,
  ],
}

const thirdCard = {
  title: "Nuestros Servicios",
  paragraphs: [
    `Ofrecemos un servicio completo personalizado en todas las áreas del interiorismo. Desarrollamos proyectos que van desde una 
    habitación hasta renovaciones a gran escala en residencias, restaurants, hoteles y todo espacio arquitectónico a intervenir.`,
  ],
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  draggable: false,
  cssEase: "linear",
  arrows: false,
  pauseOnHover: false,
}

export default function homedesign({ data }) {
  const images = data.allFile.edges
  return (
    <Layout>
      <SEO title="Home Design" />
      <section className="home-design">
        <div className="home-design__container">
          <div className="home-design__column home-design__column-no-padding" />
          <div className="home-design__column home-design__column--content-left">
            <Card {...firstCard} />
          </div>
        </div>
        <div className="home-design__container">
          <div className="home-design__column home-design__column--order-two">
            <ServicesList services={[`Diseño`, `Textura`, `Color`, `Materiales`]} />
          </div>
          <figure className="home-design__column home-design__column--content-left home-design__column--order-one">
            <Img
              fluid={images[0].node.childImageSharp.fluid}
              alt="Interior Home Design"
              className="home-design__image"
              key={"001"}
            />
          </figure>
        </div>
        <div className="home-design__container">
          <div className="home-design__column home-design__column--order-two">
            <Logo className="home-design__logo" />
          </div>
          <div className="home-design__column home-design__column--content-left home-design__column--order-one">
            <Card {...secondCard} />
          </div>
        </div>
        <div className="home-design__slider">
          <Slider {...settings}>
            <div className="home-design__slider-item">
              <Img
                fluid={images[1].node.childImageSharp.fluid}
                alt="L'atelier Des Fleurs Slide"
                className="home-design__slider-image"
              />
            </div>
            <div className="home-design__slider-item">
              <Img
                fluid={images[2].node.childImageSharp.fluid}
                alt="L'atelier Des Fleurs Slide"
                className="home-design__slider-image"
              />
            </div>
            <div className="home-design__slider-item">
              <Img
                fluid={images[3].node.childImageSharp.fluid}
                alt="L'atelier Des Fleurs Slide"
                className="home-design__slider-image"
              />
            </div>
            <div className="home-design__slider-item">
              <Img
                fluid={images[4].node.childImageSharp.fluid}
                alt="L'atelier Des Fleurs Slide"
                className="home-design__slider-image"
              />
            </div>
          </Slider>
        </div>

        <div className="home-design__container">
          <div className="home-design__column home-design__column-no-padding" />
          <div className="home-design__column home-design__column--content-left">
            <Card {...thirdCard} />
          </div>
        </div>
        <div className="home-design__container">
          <div className="home-design__column">
            <ServicesList services={[`Restaurants`, `Hotels`, `Home`, `Office`]} />
          </div>
          <figure className="home-design__column">
            <Img
              fluid={images[5].node.childImageSharp.fluid}
              alt="Services Home Design"
              className="home-design__image"
              key={"002"}
            />
          </figure>
        </div>
        <div className="home-design__container">
          <div className="home-design__column home-design__column-no-padding" />
          <div className="home-design__column home-design__column--content-left">
            <Card title="Platícanos tu Proyecto" paragraphs={[`hola@latelierdesfleurs.com.mx`]} isStretch={true} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GET_HOME_DESIGN_IMAGES {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/home-design/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              originalName
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }
    }
  }
`
